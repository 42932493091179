var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mew-component--home-footer textDark--text" },
    [
      _c(
        "div",
        { staticClass: "desktop-content d-none d-lg-block" },
        [
          _c(
            "v-sheet",
            {
              staticClass: "py-2",
              staticStyle: { "border-radius": "0" },
              attrs: { color: "textDark" },
            },
            [
              _c("v-container", [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "cyan--text text--lighten-3 ma-0",
                        attrs: {
                          href:
                            "https://github.com/MyEtherWallet/MyEtherWallet/releases/tag/v" +
                            _vm.version,
                          target: "_blank",
                        },
                      },
                      [_vm._v("v" + _vm._s(_vm.version))]
                    ),
                    _c("v-spacer"),
                    _c(
                      "p",
                      { staticClass: "teal--text text--lighten-1 ma-0" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("footer.copyright", {
                                year: new Date().getFullYear(),
                              })
                            ) +
                            " "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "cyan--text text--lighten-3",
                            attrs: {
                              href: "https://www.coingecko.com/en",
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("footer.coingecko")))]
                        ),
                        _vm._v(". "),
                      ]
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-sheet",
                      { attrs: { width: "150", color: "transparent" } },
                      [
                        _c("v-select", {
                          attrs: {
                            "append-icon": "mdi-chevron-down",
                            items: _vm.languages,
                            "item-text": "name",
                            "item-value": "value",
                            "return-object": "",
                            "single-line": "",
                            dark: "",
                          },
                          model: {
                            value: _vm.select,
                            callback: function ($$v) {
                              _vm.select = $$v
                            },
                            expression: "select",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mobile-content d-block d-lg-none" },
        [
          _c(
            "v-sheet",
            { staticClass: "py-9", attrs: { color: "textDark" } },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-sheet",
                    {
                      staticClass: "mx-auto",
                      attrs: { color: "transparent", "max-width": "500px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-center justify-space-between",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "cyan--text text--lighten-3 ma-0",
                              attrs: {
                                href:
                                  "https://github.com/MyEtherWallet/MyEtherWallet/releases/tag/v" +
                                  _vm.version,
                                target: "_blank",
                              },
                            },
                            [_vm._v("v" + _vm._s(_vm.version))]
                          ),
                          _c(
                            "v-sheet",
                            { attrs: { width: "150", color: "transparent" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  "append-icon": "mdi-chevron-down",
                                  items: _vm.languages,
                                  "item-text": "name",
                                  "item-value": "value",
                                  "return-object": "",
                                  "single-line": "",
                                  dark: "",
                                },
                                model: {
                                  value: _vm.select,
                                  callback: function ($$v) {
                                    _vm.select = $$v
                                  },
                                  expression: "select",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-sheet",
                        {
                          staticClass: "mx-auto",
                          attrs: { color: "transparent", "max-width": "300px" },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "teal--text text--lighten-1 mt-6 mb-0 text-center",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("footer.copyright", {
                                      year: new Date().getFullYear(),
                                    })
                                  ) +
                                  " "
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "cyan--text text--lighten-3",
                                  attrs: {
                                    href: "https://www.coingecko.com/en",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("footer.coingecko")))]
                              ),
                              _vm._v(". "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }